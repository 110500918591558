import { observable, action, computed, reaction, makeObservable } from 'decorators';
import { toJS } from 'mobx';
import Routes, { nav as NavRoutes } from 'pages/routes';
import { splitQuery, splitWindowQuery } from 'utils/query';
import Navigo from 'navigo/lib/navigo';
import auth from './auth';
import log from './log';
import globalEvents, { CancelPageChangeError } from './globalEvents';
import pageTracking from './pageTracking';
import { hashObj } from 'services/hasher';
import { scrollToTop } from 'utils/dom';
import theme from 'theme';

import AddIcon from 'mdi-material-ui/Plus';

// Router wrapper for Navigo
function startRouter(root, routes, resolve, notFound) {
  const router = new Navigo(root, { strategy: 'ONE', noMatchWarning: false, hash: false });

  // We add the routes like this to preserve order
  routes.forEach((r) => {
    router.on({
      [r.path]: { as: r.name, uses: r.func }
    });
  });

  if (notFound) {
    router.notFound(notFound);
  }
  if (resolve) {
    router.resolve();
  }
  return router;
}

function loadRoute(name) {
  if (!name) { return undefined; }
  return Routes.find((r) => r.name === name);
};

class Router {
  @observable isInit = false;
  @observable name = null;
  @observable.shallow params = null;
  @observable.shallow queryParts = {};
  @observable.shallow hashParts = {};
  @observable currentClientId;

  @observable _forceNotFound = false;

  constructor() {
    makeObservable(this);
    this._routerHashCount = 0;
    const mappedRoutes = Routes.map(r => this._buildRouteObj(r));

    this._router = startRouter('/', mappedRoutes, true, null, action(query => (this.name = null)));
    this.routesByName = {};
    Routes.forEach((r) => {
      this.routesByName[r.name] = r;
    });

    // update url on state changes
    this._urlDispose = reaction(() => ({ n: this.name, f: this.notFound, p: this.params, q: this.query }), () => this._urlTask());
    this._authWatchDispose = reaction(() => ({ a: auth.isAuthed, u: auth.user }), () => this._authWatch());

    // Watch for changes to the hash
    const hashChangeEvent = () => this._refreshHash();
    window.addEventListener('popstate', hashChangeEvent, false);
    this._hashEventDispose = () => window.removeEventListener('popstate', hashChangeEvent, false);

    // If the theme domain does not match current domain, change domains
    const currentDomain = window.location.host;
    reaction(() => theme.loadingBrand || theme.brand.domain, () => {
      if (theme.loadingBrand) { return; }
      if (theme.brand.domain !== currentDomain) {
        auth.redirectToBrandUrl(theme.brand.domain);
      }
    });
  }

  @computed get query() {
    return this._buildQueryUrl(this.queryParts, this.hashParts);
  }

  @computed get currentRoute() {
    return loadRoute(this.name);
  }

  @computed get currentNavRoute() {
    const route = this.currentRoute;
    if (!route) { return undefined; }

    return this.navRouteData.find(r => r.name === route.name);
  }

  @computed get navPath() {
    const nr = this.currentNavRoute;
    return nr?.navPath;
  }

  @computed get notFound() {
    if (this._forceNotFound) { return true; }

    let notFound = false;
    const navData = this.navRouteData.find(r => r.name === this.name);
    if (navData) {
      if (auth.isLockedOut && !navData.showExpired) {
        setTimeout(() => this.navigateTo('subscription'), 0);
        notFound = true;
      }
    } else {
      notFound = true;
    }
    return notFound;
  }

  @action setNotFound() {
    this._forceNotFound = true;
  }

  navigateTo(name, params, query, hash, { replace } = {}) {
    let parts;
    if (hash && !query) { query = {}; }

    if (typeof query === 'object') {
      parts = query || {};
      hash = hash || {};
    } else {
      const splitParts = splitQuery(query);
      parts = splitParts.query;
      hash = splitParts.hash;
    }
    delete parts.iframe;
    params = toJS(params) || {};
    Object.keys(params).forEach(k => (params[k] = params[k] + ''));

    // Check if we are already on this page...
    if (name === this.name
      && hashObj(params) === hashObj(this.params)
      && hashObj(parts) === hashObj(this.queryParts)
      && hashObj(hash) === hashObj(this.hashParts)) {
      return;
    }

    const executeChange = action(() => {
      // We should always scroll to the top when changing pages, even if we end up on the same component
      scrollToTop();

      this._lastNavigateOpts = { replace };
      this.name = name;
      this.params = params;
      this.queryParts = parts;
      this.hashParts = hash;
      this._forceNotFound = false;
      this.isInit = true;
    });

    if (this.name && name !== this.name) {
      globalEvents.runPageHanders()
        .then(action(() => {
          executeChange();
          return null;
        }))
        .catch(CancelPageChangeError, () => {
          // If we cancel, make sure the url is consistent (could be wrong if back button was pressed)
          this._urlTask();
          return null;
        })
        .done();
    } else {
      executeChange();
    }
  }

  navigateToNewWindow(name, params, query, hash) {
    const url = this.buildUrl(name, params, query, hash);
    window.open(url);
  }

  buildUrl(name, params, queryParts, hashParts) {
    return this._buildUrlFromRouter(name, params, queryParts, hashParts);
  }

  pushUrl(url) {
    if (url.length > 0 && url[0] === '/') {
      url = url.substr(1);
    }
    url = '/' + url;
    this._router.resolve(url);
  }

  @action setCurrentClient(clientId) {
    this.currentClientId = clientId;
  }

  dispose() {
    this._hashEventDispose();
    this._authWatchDispose();
    this._urlDispose();
    this._router.destroy();
  }

  @computed get navRoutes() {
    const nav = [];
    const currentPath = this.navPath;

    let newOrgRoute = null;
    this.navRouteData.forEach(r => {
      if (r.name === 'organization.new') {
        newOrgRoute = r;
      }

      const rr = Routes.find(rrr => rrr.name === r.name);
      if (!rr || !r.showNav || !r.navPath || (auth.isLockedOut && !r.showExpired)) { return; }

      const routeNavPath = r.navPath;
      let currentNav = nav;
      let parentNav = null;
      const segments = routeNavPath.split('.');
      // Take all the segments except the last
      segments.slice(0, -1).forEach((s) => {
        const name = s.replace(/&#46;/g, '.');
        let currentNavObj = currentNav.find((n) => n.name === name);
        if (!currentNavObj) {
          currentNavObj = { name, children: [], parent: parentNav };
          currentNav.push(currentNavObj);
        }
        parentNav = currentNavObj;
        currentNav = currentNavObj.children;
      });

      const navName = segments.slice(-1)[0].replace(/&#46;/g, '.');
      const isActive = routeNavPath === currentPath;
      let parent = parentNav;
      while (parent) {
        if (isActive) {
          parent.isActive = true;
        }
        if (r.isNavRoot && !parent.route) {
          parent.route = rr;
        }
        parent = parent.parent;
      }

      const action = rr?.action;
      currentNav.push({ name: navName, action, route: action ? null : rr, isActive });
    });

    const user = auth.user;
    if (user) {
      const orgs = nav[nav.length - 1];
      const otherOrgs = user.otherOrgs || [];
      const toSwitch = otherOrgs.map(o => {
        return {
          name: o.orgName,
          id: o.orgId,
          action: () => auth
            .switchOrg(o.orgId)
            .catch(err => {
              log.error('Error while org switching', err);
            })
            .done()
        };
      });
      toSwitch.push({ name: 'New Account', route: newOrgRoute, icon: <AddIcon /> });
      if (user.roles.isAdmin) {
        toSwitch.unshift({
          renderSwitchOrg: true
        });
      }

      orgs.children.unshift({ name: 'Switch', children: toSwitch, parent: orgs });
      orgs.children.push({ name: 'Logout', action: auth.logOut.bind(auth) });
    }

    // Clean up single item menus (only if they have routing)
    const checkChildren = (ch) => {
      for (const c of ch) {
        if (!c.route || !c.children) { continue; }
        checkChildren(c.children);
        if (c.children.length === 1) { c.children = null; }
      }
    };
    checkChildren(nav);

    return nav;
  }

  @computed get navRouteData() {
    const user = auth.user;
    return NavRoutes(user);
  }

  @action _urlTask() {
    if (!this.name || this.notFound) { return; }

    const path = this._buildUrlFromRouter(this.name, this.params, this.queryParts, this.hashParts);

    // Prevent a double resolve
    let currentPath = window.location.href.replace(window.location.origin, '');
    if (!currentPath) { currentPath = '/'; }
    if (currentPath !== path) {
      let historyMethod = 'pushState';

      if (this._lastNavigateOpts) {
        const { replace } = this._lastNavigateOpts;
        if (replace) { historyMethod = 'replaceState'; }
        this._lastNavigateOpts = null;
      }

      this._routingUsingHash = path.split('#').length > 1 ? historyMethod : undefined;
      this._routerHashCount = 1;
      this._router.navigate(path, { historyAPIMethod: historyMethod });
      pageTracking.pageChange(path);
    }
  }

  @action _authWatch() {
    if (!auth.user) {
      this._currentUserId = null;
      this.currentClientId = null;
      return;
    }

    const changedUser = this._currentUserId !== auth.user.id;
    if (changedUser || this._currentOrgId !== auth.user.orgId) {
      const firstHit = this._currentUserId === undefined;

      this._currentUserId = auth.user.id;
      this._currentOrgId = auth.user.orgId;

      // If we just switch auth status, then reset to home page to prevent strange states
      if (!firstHit) {
        this.currentClientId = null;
        if (changedUser && this.name !== 'home') {
          this.navigateTo('home');
        }
      }
    }
  }

  _refreshHash() {
    if (this._routerHashTimeout) {
      clearTimeout(this._routerHashTimeout);
    }

    this._routerHashCount++;
    this._routerHashTimeout = setTimeout(() => {
      // HACK: when routing by hash navigo will add two history items
      if (this._routingUsingHash) {
        const count = this._routerHashCount - 1;
        this._routingUsingHash = undefined;
        this._routerHashCount = 0;

        if (count > 0) {
          history.go(-count);
        }
      }
      this._routerHashTimeout = null;
      const parts = splitWindowQuery();
      this.navigateTo(this.name, this.params, parts.query || {}, parts.hash || {});
    }, 1);
  }

  _buildRouteObj(route) {
    return {
      name: route.name,
      path: route.path,
      func: m => {
        this.navigateTo(route.name, m.data, m.params, splitQuery(m.hashString).query);
      }
    };
  }

  _buildUrlFromRouter(name, params, query, hash) {
    if (!name) { return null; }
    const path = this._router.generate(name, params);
    const queryUrl = this._buildQueryUrl(query, hash);
    return `${path}${queryUrl}`;
  }

  _buildQueryUrl(queryParts, hashParts) {
    if (!queryParts && !hashParts) { return ''; }

    let query = Object.keys(queryParts || {}).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(queryParts[k])}`).join('&');
    if (query) { query = '?' + query; }
    const hash = Object.keys(hashParts || {}).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(hashParts[k])}`).join('&');
    if (hash) {
      query = `${query}#${hash}`;
    }
    return query;
  }
}

export default new Router();