import { memo, useMemo } from 'react';
import cc from 'classnames';
import { Avatar } from '@material-ui/core';
import { makeStyles } from 'hooks';
import Image from 'components/image';
import md5 from 'md5';
import auth from 'services/auth';

import { AccountCircle } from 'mdi-material-ui';

const useStyles = makeStyles(theme => ({
  avatar: {
    background: theme.palette.common.white
  },
  defaultAvatar: {
    color: theme.palette.grey[700],
    width: '120%',
    height: '120%'
  },
  image: {
    maxHeight: '100%'
  }
}));

export default memo(function Gravitar({ size, file, email, defaultImage, className, fallbackComponent, onFileLoaded: handleFileLoaded, onLoad: handleOnLoad, cacheBust, alt, ...other }) {
  const classes = useStyles();
  const url = useMemo(() => getGravatarImage(email, defaultImage, { size, hideIfDisabled: true }), [ email, size, defaultImage ]);

  return <Avatar {...other} className={cc(classes.avatar, className)}>
    <Image
      file={file}
      src={url}
      fallbackSrc={defaultImage}
      fallbackComponent={fallbackComponent || <AccountCircle className={classes.defaultAvatar} />}
      onLoad={handleOnLoad}
      onFileLoaded={handleFileLoaded}
      cacheBust={cacheBust}
      className={classes.image}
      alt={alt || 'Profile Image'}
    />
  </Avatar>;
});

export function getGravatarImage(email, fallbackSrc, { hideIfDisabled, size } = {}) {
  let url = fallbackSrc ? window.location.origin + fallbackSrc : null;
  if (email && !auth.user?.disableGravatar) {
    const hash = md5(email.trim().toLowerCase());
    url = `https://www.gravatar.com/avatar/${hash}?s=${size || 200}&d=${(url ? encodeURIComponent(url) : '404')}`;
  } else if (hideIfDisabled) {
    url = null;
  }
  return url;
};