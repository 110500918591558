import toastr from './toastr';

class Log {
  constructor() {
    this._metricMap = new Map();

    // We need to bind a couple methods so they can be used conveniently
    this.catchAndNotify = this.catchAndNotify.bind(this);
    this.log = this.log.bind(this);
    this.error = this.error.bind(this);

    // Once a minute push for all metrics
    this._interval = setInterval(() => this._pushMetrics(), 60000);
  }

  parseErrorMessage(err) {
    const data = err?.response?.data;
    return data?.error?.message || data?.message || (err?.isCustom && err?.message);
  }

  catchAndNotify(err) {
    const message = this.parseErrorMessage(err);
    const linkName = err?.response?.data?.error?.linkName;
    if (message) {
      toastr.error({ message, linkName });
    } else {
      this.error(err?.customFallbackMessage || 'Uncaught Error', err);
      toastr.error({ message: err?.customFallbackMessage || 'Unknown error occurred', linkName });
    }
    return null;
  }

  log(msg) {
    if (IS_DEBUG) {
      window.console.log(msg);
    }
  }

  error(message, err, props) {
    let restoreMessage = false;
    if (err) {
      if (typeof err === 'string') {
        try {
          throw new Error(err);
        } catch (e) {
          err = e;
        }
      }
      if (message) {
        const temp = err.message;
        try { err.message = message; } catch {}
        message = temp;
        restoreMessage = true;
      }
    } else {
      try {
        throw new Error(message);
      } catch (e) {
        err = e;
      }
      message = null;
    }

    if (window.console?.error) { window.console.error(message, err, props); }
    if (window.appInsights) {
      const properties = {};

      if (message) {
        properties.original = message;
      };
      if (err.config) {
        properties.method = err.config.method;
        properties.url = err.config.url;
      }
      if (err.response) {
        properties.status = err.response.status;
        properties.statusText = err.response.statusText;
        properties.respMessage = err.response.data?.error?.message || err.response.data?.message;
        properties.code = err.response.data?.error?.code;
      }
      if (props) {
        Object.keys(props).forEach(k => {
          properties[k] = props[k];
        });
      }

      window.appInsights.trackException({ error: err, properties });
    }

    if (restoreMessage) {
      try { err.message = message; } catch {}
    }
  }

  trackEvent(name, properties, measurements) {
    if (window.appInsights) { window.appInsights.trackEvent({ name, properties, measurements }); }
  }

  getMetric(metricName, props) {
    let metric = this._metricMap.get(metricName);
    if (!metric) {
      metric = new Metric(metricName, props, () => this._metricMap.delete(metricName));
      this._metricMap.set(metricName, metric);
    } else {
      metric._ref++;
    }

    return new MetricWrap(metric);
  }

  _pushMetrics() {
    // We push all the metrics kind of the same time
    this._metricMap.forEach((m) => {
      m.push();
    });
  }
}

class Metric {
  constructor(name, props, onFinish) {
    this._name = name;
    this._props = props;
    this._onFinish = onFinish;
    this._ref = 1;
    this._values = [];
  }

  reduceRef() {
    if (this._ref <= 0) { return; }
    this._ref--;
    if (this._ref <= 0) {
      clearInterval(this._interval);
      this.push();
      this._onFinish();
    }
  }

  track(value) {
    // Don't support values <= 0 or null etc
    if (!value) { return; }
    value = Number(value);

    // Needs to be a number...
    if (isNaN(value) || value <= 0) { return; }
    this._values.push(value);
  }

  push() {
    const sampleCount = this._values.length;
    if (!sampleCount) { return; }

    const min = Math.min.apply(null, this._values);
    const max = Math.max.apply(null, this._values);
    const sum = this._values.reduce(function(a, b) { return a + b; });
    const average = sum / sampleCount;

    if (window.appInsights) { window.appInsights.trackMetric({ name: this._name, average, sampleCount, min, max, properties: this._props }); }
    this._values = [];
  }
}

class MetricWrap {
  constructor(metric) {
    this._metric = metric;
    this._hasDisposed = false;
  }

  track(value) { this._metric.track(value); }

  dispose() {
    if (!this._hasDisposed) {
      this._hasDisposed = true;
      this._metric.reduceRef();
    }
  }
}

export default new Log();