import { memo, Fragment, forwardRef, useState, useCallback } from 'react';
import { makeStyles } from 'hooks';
import cc from 'classnames';
import { Button as MaterialButton, Typography, Grid, IconButton, CircularProgress } from '@material-ui/core';
import Modal from 'components/modal';

const useStyles = makeStyles(theme => ({
  button: {
    position: 'relative'
  },
  loadingProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  expanded: {
    width: '100%'
  },
  error: {
    color: theme.palette.getContrastText(theme.palette.error.main),
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  success: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    }
  }
}));

export default memo(forwardRef(function Button(props, ref) {
  const {
    isLoading, disabled, onClick, children, className, expanded, nowrap, confirm, confirmLabel,
    component, confirmButtonPrimaryLabel, icon, color, modalClassName, ...other
  } = props;

  const classes = useStyles();
  const [ confirming, setConfirming ] = useState(false);

  const error = color === 'error';
  const success = color === 'success';
  const actualCol = color === 'error' || color === 'success' ? undefined : color;

  const handleFinishConfirm = useCallback(() => setConfirming(false), []);
  const handleClick = useCallback((e) => {
    // If the button is loading we shouldn't allow any action
    if (isLoading || disabled) {
      e.preventDefault();
      return;
    }
    if (confirm && !confirming) {
      setConfirming(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    onClick && onClick(e);
    setConfirming(false);
  }, [ confirm, confirming, disabled, isLoading, onClick ]);

  const isDisabled = !!(disabled || isLoading);
  const cl = cc(classes.button, className, expanded && classes.expanded);
  const Component = component || (icon ? IconButton : MaterialButton);

  const inner = nowrap ? <Typography component="span" variant="button" noWrap>{children}</Typography> : children;

  return <Fragment>
    <Component ref={ref} disabled={isDisabled} onClick={handleClick} className={cc(cl, error && classes.error, success && classes.success)} color={actualCol} {...other}>
      { inner }
      { isLoading && <CircularProgress size={24} color={actualCol} className={classes.loadingProgress} /> }
    </Component>
    { !!confirm &&
      <Modal modalClassName={modalClassName} open={confirming} label={confirmLabel} onClose={handleFinishConfirm}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            { confirm }
          </Grid>
          <Grid item>
            <Typography align="right">
              <MaterialButton onClick={handleFinishConfirm}>Cancel</MaterialButton>
              <MaterialButton color="primary" onClick={handleClick}>{ confirmButtonPrimaryLabel || 'OK' }</MaterialButton>
            </Typography>
          </Grid>
        </Grid>
      </Modal>
    }
  </Fragment>;
}));