export const acceptImageTypes = {
  'image/*': [ '.png', '.gif', '.jpeg', '.jpg', '.tif', '.heif', '.heic' ]
};
export const acceptVideoTypes = {
  'video/*': [ '.mp4', '.mov', '.wmv', '.flv', '.avi', '.webm', '.mkv', '.avchd' ]
};
export const acceptWordType = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [ '.docx' ]
};
export const acceptDocumentTypes = {
  'application/pdf': [ '.pdf' ],
  ...acceptWordType,
  ...acceptImageTypes
};
export const accept835Types = {
  'text/plain': [ '.txt', '.835', '.era', '.edi' ]
};

// We need a full list for all types here to support being able to capture it as well
export const acceptAllTypes = {
  // Text based items
  'text/csv': [ '.csv' ],
  'text/plain': [ '.txt', '.csv' ],
  'text/calendar': [ '.ics' ],
  'application/rtf': [ '.rtf' ],
  // Other Office
  'application/msword': [ '.doc', '.dot' ],
  'application/vnd.ms-excel': [ '.xls', '.xlt', '.xla', '.csv' ],
  'application/vnd.ms-powerpoint': [ '.ppt', '.pot', '.pps', '.ppa' ],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [ '.pptx' ],
  'application/vnd.openxmlformats-officedocument.presentationml.template': [ '.potx' ],
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow': [ '.ppsx' ],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [ '.xlsx' ],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template': [ '.xltx' ],
  // Google docs
  'application/vnd.google-apps.document': [ '.gdoc' ],
  'application/vnd.google-apps.presentation': [ '.gslides' ],
  'application/vnd.google-apps.spreadsheet': [ '.gsheet' ],
  'application/vnd.google-apps.drawing': [ '.gdraw' ],
  // Keynote/Numbers/Pages
  'application/x-iWork-keynote-sffkey': [ '.key' ],
  'application/x-iwork-numbers-sffnumbers': [ '.numbers' ],
  'application/x-iwork-pages-sffpages': [ '.pages' ],
  // LaTeX / TeX
  'application/x-latex': [ '.latex' ],
  'application/x-dvi': [ '.dvi' ],
  // Open Docs
  'application/vnd.oasis.opendocument.text': [ '.odt' ],
  // Zipped types
  'application/x-tar': [ '.tar', '.gz', '.tgz' ],
  'application/zip': [ '.zip' ],
  // Audio
  'audio/*': [ '.mp3', '.flac', '.wav', '.aac', '.ogg', '.aa3', '.mpga', '.dts', '.xsm', '.a52', '.xad' ],
  ...acceptDocumentTypes
};

export function isVideoType(type) {
  return (type || '').startsWith('video/');
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}

export function getScrollParent(node) {
  if (node == null) {
    // Body doesn't have overflow set on it, so we will skip it with our logic
    return document.body;
  }

  // The 10 value here is to allow for some negative margins
  if (node.scrollHeight - node.clientHeight > 18) {
    if (!node.scrollTop) {
      const overflowStyle = window.getComputedStyle(node)['overflow-y'];
      if (overflowStyle === 'visible' || overflowStyle === 'hidden') {
        return getScrollParent(node.parentNode);
      }
    }
    return node;
  } else {
    return getScrollParent(node.parentNode);
  }
}

export function getOffset(el) {
  let _x = 0;
  let _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x };
}

export function installScript(url, crossOrigin, id) {
  return new Promise((resolve, reject) => {
    const s = document.createElement('script');
    s.src = url;
    if (crossOrigin) { s.crossOrigin = 'anonymous'; }
    if (id) { s.id = id; }
    s.type = 'text/javascript';
    s.async = 'true';
    s.onload = resolve;
    s.onerror = function() { reject(new Error('Script load error')); };
    document.body.appendChild(s);
    return null;
  });
}

// https://stackoverflow.com/questions/1064089/inserting-a-text-where-cursor-is-using-javascript-jquery#1064139
export function insertAtCaret(element, text) {
  const scrollPos = element.scrollTop;
  const br = ((element.selectionStart || element.selectionStart === '0') ? 'ff' : (document.selection ? 'ie' : false));

  let strPos = 0;
  if (br === 'ie') {
    element.focus();
    const range = document.selection.createRange();
    range.moveStart('character', -element.value.length);
    strPos = range.text.length;
  } else if (br === 'ff') {
    strPos = element.selectionStart;
  }

  const front = (element.value).substring(0, strPos);
  const back = (element.value).substring(strPos, element.value.length);
  element.value = front + text + back;
  strPos = strPos + text.length;
  if (br === 'ie') {
    element.focus();
    const ieRange = document.selection.createRange();
    ieRange.moveStart('character', -element.value.length);
    ieRange.moveStart('character', strPos);
    ieRange.moveEnd('character', 0);
    ieRange.select();
  } else if (br === 'ff') {
    element.selectionStart = strPos;
    element.selectionEnd = strPos;
    element.focus();
  }

  element.scrollTop = scrollPos;
  return element.value;
}

export function htmlToText(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

export const refsHaveFocus = (refs) => {
  return refs.some(r => {
    if (!r?.current) { return false; }
    if (Array.isArray(r.current)) { return refsHaveFocus(r.current); }
    return r.current.contains && r.current.contains(document.activeElement);
  });
};

const focussableElements = 'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
export function findFirstFocusableChild(parent, nth) {
  if (!parent) { return null; }

  const focussable = Array.prototype.filter.call(
    parent.querySelectorAll(focussableElements),
    function(element) {
      // check for visibility while always include the current activeElement
      return (
        element.offsetWidth > 0 ||
        element.offsetHeight > 0
      );
    }
  );
  return focussable[nth || 0] || parent;
}